import {
  HStack,
  Button,
  Tooltip,
  IconButton,
  Popover,
  PopoverTrigger,
  PopoverContent,
  PopoverArrow,
  PopoverBody,
  Box,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { PersonOutlineOutlined, ThumbDownAlt } from "@mui/icons-material";
import React, { useEffect, useMemo, useState } from "react";
import { BiChevronDown } from "react-icons/bi";
import { BsThreeDots } from "react-icons/bs";
import { FaHourglassHalf } from "react-icons/fa";
import { FiAlertTriangle } from "react-icons/fi";
import { GiCardExchange, GiCrownedSkull, GiReturnArrow } from "react-icons/gi";
import { GrTransaction, GrUpload } from "react-icons/gr";
import { RiMenuAddFill } from "react-icons/ri";
import GaraazAlertDialogue from "../../../components/GaraazAlertDialogue";
import TextInputModal from "../../../components/TextInputModal";
import {
  globalOrderStatus,
  globalOrderActions,
  OrdersTextLabels,
} from "../enums";
import GaraazChoser from "./GaraazChoser";
import GaraazDatePickerModalETA from "./GaraazDatePickerModalETA";
import GaraazGeneralPopover from "./GaraazGeneralPopover";

import UploadInvoiceModal, { invoiceInfoType } from "./UploadInvoiceModal";
import useOrdersStore from "../store/useOrdersStore";
import { useGetOrderMediaConfig } from "../../PlaceOrderTest/queryHooks";
import { useGetAllSuppliers, useGetSingleGaraazOrder } from "../queryHooks";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import { AllocatedTo, startOrcaStates } from "./GaraazOrderComponent";
import ThumbUpAltIcon from "@mui/icons-material/ThumbUpAlt";

import services from "../services";
import useNotification, {
  useGaraazToastWithChildren,
} from "../../../utils/useNotification";
import OrderStatusChangeErrorBox from "./OrderStatusChangeErrorBox";
import { useUserDetailsContext } from "../../../context/userDetailsContext";
import GaraazCountDownClockV2 from "./GaraazCountDownClockV2";
import InvoicePopver from "./InvoicePopover";
import SearchListModal from "./SearchListModal";
import { dateFormatInvoiceApi } from "../../../utils/dateResolvers";
import Brightness1Icon from "@mui/icons-material/Brightness1";
import InvoicePopoverTrigger from "../../../components/InvoicePopoverTrigger";

type GaraazOrderComponentFooterReturnProps = {
  orderData: any;
  orderStatus: any;
  orderCancellationReason: string[];
  setOrderStatus: React.Dispatch<any>;
  handlePartConversionChangeScreen: () => void;
  handleTransactionTypeChange: (option: any) => Promise<void>;
  activesupplierOrderStatus: AllocatedTo;
  refetchAllOrders: () => void;
  setEtaMissed: React.Dispatch<React.SetStateAction<boolean>>;
  isCustomerOrderFreezed: boolean;
};

const GaraazOrderComponentFooterReturn: React.FC<
  GaraazOrderComponentFooterReturnProps
> = ({
  orderData,
  orderStatus,
  handlePartConversionChangeScreen,
  handleTransactionTypeChange,
  refetchAllOrders,
  setOrderStatus,
  setEtaMissed,
  activesupplierOrderStatus,
  isCustomerOrderFreezed,
}) => {
  const toast = useNotification();
  const garaazToast = useGaraazToastWithChildren();
  const userDetails = useUserDetailsContext();
  const orderEta = useOrdersStore((state) => state.orderEtas[orderData._id]);
  const setSelectedOrderHandler = useOrdersStore(
    (store) => store.setSelectedOrderHandler
  );
  const setOrderEtasWithOrderId = useOrdersStore(
    (state) => state.setOrderEtasWithOrderId
  );
  const savedOrderRemarks = useOrdersStore(
    (state) => state.orderRemarks[orderData._id]
  );
  const setSavedOrderRemarks = useOrdersStore(
    (state) => state.setOrderRemarksWithOrderId
  );

  const { data: mobileConfigs } = useGetOrderMediaConfig();
  const { data: allSuppliers } = useGetAllSuppliers();
  const { data: parentOrder } = useGetSingleGaraazOrder(
    orderData.parentOrderId
  );

  const orderHandlers = useOrdersStore((state) => state.orderHandlers);
  const [invoiceInfo, setInvoiceInfo] = useState({
    title: "Garaaz",
    invoiceMediaId: orderData?.invoiceInfo?.invoiceMediaId ?? "",
    invoiceNo: orderData?.invoiceInfo?.invoiceNo ?? "",
    invoiceAmount: orderData?.invoiceInfo?.invoiceAmount ?? "",
    invoiceDate: orderData?.invoiceInfo?.invoiceDate ?? "",
    invoiceStatus: orderData?.invoiceInfo?.invoiceStatus ?? "",
  });
  const [challanInfo, setChallanInfo] = useState({
    title: "Garaaz",
    challanMediaId: orderData?.challanInfo?.challanMediaId ?? "",
    challanNo: orderData?.challanInfo?.challanNo ?? "",
    challanAmount: orderData?.challanInfo?.challanAmount ?? "",
    challanDate: orderData?.challanInfo?.challanDate ?? "",
  });
  const [supplierInvoiceInfo, setSupplierInvoiceInfo] = useState<
    {
      invoiceMediaId: any;
      invoiceNo: any;
      invoiceAmount: any;
      invoiceDate: any;
    }[]
  >(orderData.allocatedTo?.map((item: any) => item.invoiceInfo));
  const [supplierChallanInfo, setSupplierChallanInfo] = useState<
    {
      challanMediaId: any;
      challanNo: any;
      challanAmount: any;
      challanDate: any;
    }[]
  >(orderData.allocatedTo?.map((item: any) => item.challanInfo));
  const [ackClockValue0, setAckClockValue0] = useState(
    Math.floor(new Date(orderData.orderDate).getTime() / 1000)
  );

  const [ackClockValue100, setAckClockValue100] = useState(
    Math.floor(new Date(orderData.orderDate).getTime() / 1000) +
      mobileConfigs?.threshold_ack_order_mins * 60
  );
  const [assignedSupplier, setAssignedSupplier] = useState<AllocatedTo[]>(
    orderData?.allocatedTo || []
  );
  const [customerSupplierAssociations, setCustomerSupplierAssociations] =
    useState<{}[]>([]);
  const [cancellationReason, setCancellationReason] = useState(
    orderData?.cancellationReason ?? ""
  );
  const [ackTimeMissed, setAckTimeMissed] = useState(false);
  const [parentOrderActiveSupplier, setParentOrderActiveSupplier] =
    useState<any>(null);

  const orderHandlerOptions = orderHandlers.map((orderHandler) => {
    return {
      name: orderHandler.user.firstName + " " + orderHandler.user.lastName,
      menuItemIcon: (
        <Brightness1Icon
          sx={{
            color: orderHandler?.isActive ? "green" : "red",
            fontSize: "10px",
          }}
        />
      ),
      ...orderHandler,
    };
  });
  const orderHandlerName =
    (orderData?.orderHandler[0]?.firstName ?? "-") +
    " " +
    (orderData?.orderHandler[0]?.lastName ?? "");
  const transactionTypeOption = [{ type: "Facilitation" }, { type: "Trading" }];

  const {
    isOpen: etaPickerModalOpen,
    onOpen: openETAPickerModal,
    onClose: closeETAPickerModal,
  } = useDisclosure();

  const {
    isOpen: updateInvoiceModalOpen,
    onOpen: openUpdateInvoiceModal,
    onClose: closeUpdateInvoiceModal,
  } = useDisclosure();

  const {
    isOpen: orderRemarksModelOpen,
    onOpen: openOrdersRemarksModel,
    onClose: closeOrdersRemarksModel,
  } = useDisclosure();

  const {
    isOpen: isOpenACCEPT_RETURNOrderDialogue,
    onOpen: onOpenACCEPT_RETURNOrderDialogue,
    onClose: onCloseACCEPT_RETURNOrderDialogue,
  } = useDisclosure();

  const {
    isOpen: isOpenREJECT_RETURNOrderDialogue,
    onOpen: onOpenREJECT_RETURNOrderDialogue,
    onClose: onCloseREJECT_RETURNOrderDialogue,
  } = useDisclosure();

  const {
    isOpen: isSupplierModalListOpen,
    onOpen: onSupplierModalListOpen,
    onClose: onSupplierModalListClose,
  } = useDisclosure();

  useEffect(() => {
    let customerSupplierAssociationsTemp: {}[] = [];

    orderData?.customer?.supplierAssociations?.map(
      (association: any, index: number) => {
        let customerSupplierAssociationObject = {
          "Supplier Name": association?.supplier?.name
            ? association?.supplier?.businessName
            : "-",
          "Branch Name": association?.supplier?.branches
            ? association?.supplier?.branches[0]?.branchName
            : "-",

          "Branch Code": association?.supplier?.branches
            ? association?.supplier?.branches[0]?.branchCode
            : "-",
          "Supplier Code": association?.supplierCode
            ? association.supplierCode
            : "-",
        };
        customerSupplierAssociationsTemp.push(
          customerSupplierAssociationObject
        );
      }
    );
    setCustomerSupplierAssociations(customerSupplierAssociationsTemp);
    setInvoiceInfo({
      title: "Garaaz",
      invoiceMediaId: orderData?.invoiceInfo?.invoiceMediaId,
      invoiceNo: orderData?.invoiceInfo?.invoiceNo,
      invoiceDate: orderData?.invoiceInfo?.invoiceDate,
      invoiceAmount: orderData?.invoiceInfo?.invoiceAmount,
      invoiceStatus: orderData?.invoiceInfo?.invoiceStatus,
    });
    setChallanInfo({
      title: "Garaaz",
      challanMediaId: orderData?.challanInfo?.challanMediaId,
      challanNo: orderData?.challanInfo?.challanNo,
      challanDate: orderData?.challanInfo?.challanDate,
      challanAmount: orderData?.challanInfo?.challanAmount,
    });
    setSupplierInvoiceInfo(
      orderData.allocatedTo.map((item: any) => ({
        ...item.invoiceInfo,
        supplierId: item.supplierId,
      }))
    );
    setSupplierChallanInfo(
      orderData.allocatedTo.map((item: any) => ({
        ...item.challanInfo,
        supplierId: item.supplierId,
      }))
    );
    setAssignedSupplier(orderData?.allocatedTo);
    if (orderData.orderType === "RETURN") {
      (async () => {
        const parentOrder = await services.getSingleOrder(
          orderData.parentOrderId
        );
        const activeSupplier = parentOrder?.allocatedTo?.find(
          (item: any) => item.isActive === true
        );

        setParentOrderActiveSupplier(activeSupplier?.supplierId);
      })();
    }

    setAckClockValue0(
      Math.floor(new Date(orderData.orderDate).getTime() / 1000)
    );
    setAckClockValue100(
      Math.floor(new Date(orderData.orderDate).getTime() / 1000) +
        mobileConfigs?.threshold_ack_order_mins * 60
    );
    setAckTimeMissed(false);
  }, [orderData]);

  const saveOrderRemarks = async (remarks: string) => {
    try {
      let newOrderRemarks = [
        ...savedOrderRemarks,
        {
          userName: `${userDetails.firstName} ${userDetails.lastName}`,
          remarks: remarks,
        },
      ];
      setSavedOrderRemarks(orderData._id, newOrderRemarks);
      await services.setOrderRemarks(orderData._id, {
        userName: `${userDetails.firstName} ${userDetails.lastName}`,
        remarks,
      });
    } catch (error) {}
  };

  const handleorderETAModalSubmit = async (
    etaGivenTime: string,
    eta: string,
    reason: string
  ) => {
    try {
      const newEtaChangeReason = [...orderEta, { etaGivenTime, eta, reason }];
      setOrderEtasWithOrderId(orderData._id, newEtaChangeReason);
      await services.setOrderETA(orderData._id, {
        etaGivenTime,
        eta,
        reason,
      });
      setEtaMissed(false);
    } catch (error) {}
  };

  const handleOrderHandlerChange = async (item: any) => {
    await services.updateOrder(orderData._id, {
      orderHandler: item.userId,
    });
    setSelectedOrderHandler(orderData._id, item.user);
  };

  const handleAssignSupplierChange = async (item: any) => {
    const matchingSupplierAssociation =
      orderData?.customer?.supplierAssociations?.find(
        (association: any) => association.supplierId === item._id
      );

    const assignedBranchId = matchingSupplierAssociation
      ? matchingSupplierAssociation.supplierBranchId
      : null;

    try {
      await services.checkNextStateOfGaraazOrders(
        orderData._id,
        globalOrderStatus.ALLOCATE
      );

      const updatedAllocatedTo = orderData?.allocatedTo?.map(
        (supplierInfo: AllocatedTo) => {
          if (supplierInfo.supplierId === item._id) {
            return {
              ...supplierInfo,
              branchId: assignedBranchId,
              orderStatus:
                supplierInfo?.orderStatus === globalOrderStatus.REVOKED
                  ? globalOrderStatus.NEW
                  : supplierInfo.orderStatus,
              isActive: true,
            };
          } else {
            return {
              ...supplierInfo,
              orderStatus:
                supplierInfo?.orderStatus === globalOrderStatus.CANCELLED ||
                supplierInfo?.orderStatus === globalOrderStatus.READY
                  ? supplierInfo?.orderStatus
                  : globalOrderStatus.REVOKED,
              isActive: false,
            };
          }
        }
      );

      const newSupplier = {
        supplierId: item._id,
        branchId: assignedBranchId,
        orderStatus: globalOrderStatus.RETURN_REQUESTED,
        isActive: true,
      };

      const isSupplierAlreadyInAllocation = assignedSupplier.some(
        (supplier) => supplier.supplierId === newSupplier.supplierId
      );

      if (!isSupplierAlreadyInAllocation) {
        updatedAllocatedTo.push(newSupplier);
      }

      await services.updateOrder(orderData._id, {
        allocatedTo: updatedAllocatedTo,
      });
      setAssignedSupplier(updatedAllocatedTo);
      refetchAllOrders();
    } catch (error: any) {
      const errorFromResponse = error?.response?.data?.errorMsg;
      const title = !!errorFromResponse.length
        ? errorFromResponse[0]?.title
        : OrdersTextLabels.ACCESS_DENIED_ORDER_STATUS_INVALID;

      garaazToast({
        title: title,
        status: "error",
        render: <OrderStatusChangeErrorBox errorMsg={errorFromResponse} />,
      });
    }
  };

  const handleChangeOrderStatus = async (
    status: globalOrderStatus | globalOrderActions,
    modalCloseFunction?: () => void
  ) => {
    try {
      await services.checkNextStateOfGaraazOrders(orderData._id, status);
      setOrderStatus(status);
      !!refetchAllOrders && refetchAllOrders();
    } catch (error: any) {
      const errorFromResponse = error?.response?.data?.errorMsg;
      const title = !!errorFromResponse.length
        ? errorFromResponse[0]?.title
        : OrdersTextLabels.ACCESS_DENIED_ORDER_STATUS_INVALID;
      garaazToast({
        title: title,
        status: "error",
        render: <OrderStatusChangeErrorBox errorMsg={errorFromResponse} />,
      });
    } finally {
      if (modalCloseFunction) modalCloseFunction();
    }
  };

  const handleUpdateInvoiceModalSubmit = async (
    pdfFile: File | null = null,
    invoiceInfo: invoiceInfoType,
    challanInfo: any,
    fileType: string
  ) => {
    try {
      let updatedParts = {};
      if (fileType === "Invoice") {
        if (
          pdfFile !== null &&
          invoiceInfo.invoiceNo &&
          invoiceInfo.invoiceAmount
        ) {
          const formData = new FormData();
          formData.append("media", pdfFile);
          const mediaResult = await services.addMedia(formData);

          updatedParts = {
            invoiceInfo: {
              ...invoiceInfo,
              invoiceMediaId: mediaResult[0]._id,
              invoiceDate: dateFormatInvoiceApi(invoiceInfo.invoiceDate),
            },
          };
        } else {
          updatedParts = {
            invoiceInfo: {
              ...invoiceInfo,
              invoiceDate: dateFormatInvoiceApi(invoiceInfo.invoiceDate),
              invoiceMediaId:
                invoiceInfo.invoiceNo &&
                invoiceInfo.invoiceAmount &&
                invoiceInfo.invoiceDate
                  ? orderData?.invoiceInfo?.invoiceMediaId
                  : undefined,
            },
          };
        }

        const updatedOrderResponse = await services.updateOrder(
          orderData._id,
          updatedParts
        );

        setInvoiceInfo({
          title: "Garaaz",
          invoiceMediaId: updatedOrderResponse.invoiceInfo.invoiceMediaId,
          invoiceNo: updatedOrderResponse.invoiceInfo.invoiceNo,
          invoiceAmount: updatedOrderResponse.invoiceInfo.invoiceAmount,
          invoiceDate: updatedOrderResponse.invoiceInfo.invoiceDate,
          invoiceStatus: updatedOrderResponse.invoiceInfo.invoiceStatus,
        });

        toast({
          description: OrdersTextLabels.INVOICE_UPLOADED_SUCCESSFULLY,
          status: "success",
        });
      } else {
        if (
          pdfFile !== null &&
          challanInfo.challanNo &&
          challanInfo.challanAmount
        ) {
          const formData = new FormData();
          formData.append("media", pdfFile);
          const mediaResult = await services.addMedia(formData);

          updatedParts = {
            challanInfo: {
              ...challanInfo,
              challanMediaId: mediaResult[0]._id,
              challanDate: dateFormatInvoiceApi(challanInfo.challanDate),
            },
          };
        } else {
          updatedParts = {
            challanInfo: {
              ...challanInfo,
              challanDate: dateFormatInvoiceApi(challanInfo.challanDate),
              challanMediaId:
                challanInfo.challanNo &&
                challanInfo.challanAmount &&
                challanInfo.challanDate
                  ? orderData?.challanInfo?.challanMediaId
                  : undefined,
            },
          };
        }

        const updatedOrderResponse = await services.updateOrder(
          orderData._id,
          updatedParts
        );

        setChallanInfo({
          title: "Garaaz",
          challanMediaId: updatedOrderResponse.challanInfo.challanMediaId,
          challanNo: updatedOrderResponse.challanInfo.challanNo,
          challanAmount: updatedOrderResponse.challanInfo.challanAmount,
          challanDate: updatedOrderResponse.challanInfo.challanDate,
        });

        toast({
          description: OrdersTextLabels.CHALLAN_UPLOADED_SUCCESSFULLY,
          status: "success",
        });
      }
    } catch (error) {
      toast({
        description: OrdersTextLabels.SOMETHING_WENT_WRONG,
        status: "error",
      });
    }
  };

  const uniqueNumbersInQuickEtas = useMemo(() => {
    return mobileConfigs?.quick_etas_mins
      ?.split(",")
      ?.filter(
        (value: any, index: number, array: any) =>
          array.indexOf(value) === index && !isNaN(parseInt(value))
      );
  }, [mobileConfigs]);

  async function onAckTimeComplete() {
    const response = await services.throwACKMissedEvent(orderData._id);
  }

  return (
    <HStack
      flex={1}
      height={"12%"}
      borderRadius={"0px 0px 8px 8px"}
      borderTop={"1px solid lightgrey"}
      style={{
        margin: 0,
      }}
      display={"flex"}
      padding={`${startOrcaStates.includes(orderStatus) ? 0 : "0.8rem"}`}
      py={`${startOrcaStates.includes(orderStatus) ? 0 : "1.5rem"}`}
      alignItems={"center"}
      bgColor={"white"}
    >
      {startOrcaStates.includes(orderStatus) ? (
        <>
          <Button
            borderTopLeftRadius={0}
            borderTopRightRadius={0}
            height={"100%"}
            width={"100%"}
            bg={"green.500"}
            _hover={{ bg: "green.600" }}
            color={"white"}
            onClick={() => handleChangeOrderStatus(globalOrderStatus.ACK)}
          >
            <Text fontSize={"lg"}>ACK</Text>
            {!orderEta.length && (
              <Box ml="5" display={"flex"}>
                {!ackTimeMissed && (
                  <GaraazCountDownClockV2
                    componentSize="small"
                    value0={ackClockValue0}
                    value100={ackClockValue100}
                    onCompleteTime={onAckTimeComplete}
                    isCompleted={ackTimeMissed}
                    setIsCompleted={setAckTimeMissed}
                  ></GaraazCountDownClockV2>
                )}

                {ackTimeMissed && (
                  <HStack spacing={1}>
                    <FiAlertTriangle
                      color="red"
                      style={{ marginLeft: "2px" }}
                    />
                    <Text>ACK Missed</Text>
                  </HStack>
                )}
              </Box>
            )}
          </Button>
        </>
      ) : (
        <>
          <HStack spacing={3} w="45%">
            <Box>
              <Tooltip label="Flip View">
                <IconButton
                  icon={<GiCardExchange />}
                  backgroundColor={"#BBE6E3"}
                  color={"black"}
                  aria-label={""}
                  onClick={handlePartConversionChangeScreen}
                />
              </Tooltip>
            </Box>
            <Box>
              <GaraazChoser
                ButtonIcon={<PersonOutlineOutlined />}
                ButtonToolTipLabel="Assign Order Handler"
                MenuItemOnChange={handleOrderHandlerChange}
                displayKey="name"
                options={orderHandlerOptions}
                MenuButtonProps={{ isDisabled: isCustomerOrderFreezed }}
                showIcon={true}
              />
            </Box>
            <Box>
              <Tooltip label="Accept Return">
                <IconButton
                  icon={<ThumbUpAltIcon />}
                  backgroundColor={"#BBE6E3"}
                  color={"black"}
                  aria-label={""}
                  onClick={onOpenACCEPT_RETURNOrderDialogue}
                  _hover={{ backgroundColor: "green.400" }}
                  isDisabled={isCustomerOrderFreezed}
                />
              </Tooltip>
              <GaraazAlertDialogue
                alertBodyText="Are you sure? You can't undo this action afterwards."
                alertDialogueHeaderText="Accept Return Order"
                onSubmitBtn={() =>
                  handleChangeOrderStatus(
                    globalOrderActions.CUSTOMER_RETURNED,
                    onCloseACCEPT_RETURNOrderDialogue
                  )
                }
                isOpen={isOpenACCEPT_RETURNOrderDialogue}
                onClose={onCloseACCEPT_RETURNOrderDialogue}
                onOpen={onOpenACCEPT_RETURNOrderDialogue}
                submitBtnText="Confirm"
              />
            </Box>
            <Box>
              <Tooltip label="Reject Return">
                <IconButton
                  icon={<ThumbDownAlt />}
                  backgroundColor={"#BBE6E3"}
                  color={"black"}
                  aria-label={""}
                  onClick={onOpenREJECT_RETURNOrderDialogue}
                  _hover={{ backgroundColor: "red.400" }}
                  isDisabled={isCustomerOrderFreezed}
                />
              </Tooltip>
              <GaraazAlertDialogue
                alertBodyText="Are you sure? You can't undo this action afterwards."
                alertDialogueHeaderText="Reject Return Order"
                onSubmitBtn={() =>
                  handleChangeOrderStatus(
                    globalOrderActions.CUSTOMER_RETURN_REJECTED,
                    onCloseREJECT_RETURNOrderDialogue
                  )
                }
                isOpen={isOpenREJECT_RETURNOrderDialogue}
                onClose={onCloseREJECT_RETURNOrderDialogue}
                onOpen={onOpenREJECT_RETURNOrderDialogue}
                submitBtnText="Confirm"
              />
            </Box>

            <Box>
              <Tooltip label="Assign Supplier">
                <IconButton
                  icon={<GiCrownedSkull />}
                  backgroundColor={"#BBE6E3"}
                  color={"black"}
                  aria-label={""}
                  onClick={onSupplierModalListOpen}
                  isDisabled={isCustomerOrderFreezed}
                />
              </Tooltip>
              <SearchListModal
                options={allSuppliers?.filter(
                  (item: any) => item._id === parentOrderActiveSupplier
                )}
                displayKey="businessName"
                handleSelectListItem={handleAssignSupplierChange}
                modalHeading="Assign Supplier"
                searchInputPlaceholder="Search Supplier By Name"
                isOpen={isSupplierModalListOpen}
                onClose={onSupplierModalListClose}
                zeroOptionsMessage={
                  <>
                    <Text>
                      No Supplier Found that caters to the specified brands.
                    </Text>
                    <Text>Split the Order or find new supplier.</Text>
                  </>
                }
              ></SearchListModal>
            </Box>
            <Box>
              <Tooltip label="ETA">
                <IconButton
                  icon={<FaHourglassHalf />}
                  backgroundColor={"#BBE6E3"}
                  color={"black"}
                  aria-label={""}
                  onClick={openETAPickerModal}
                  isDisabled={isCustomerOrderFreezed}
                />
              </Tooltip>
              <GaraazDatePickerModalETA
                modelHeader="Please provide ETA for Customer"
                isOpen={etaPickerModalOpen}
                orderETAs={orderEta}
                handleSubmit={handleorderETAModalSubmit}
                onClose={closeETAPickerModal}
                modifiedOrderETAs={uniqueNumbersInQuickEtas}
              />
            </Box>
            <Box>
              <Tooltip label="Upload Invoice">
                <IconButton
                  isDisabled={
                    activesupplierOrderStatus?.orderStatus !==
                      globalOrderStatus.ACCEPTED || isCustomerOrderFreezed
                  }
                  icon={<GrUpload />}
                  backgroundColor={"#BBE6E3"}
                  color={"black"}
                  aria-label={""}
                  onClick={openUpdateInvoiceModal}
                />
              </Tooltip>
              <UploadInvoiceModal
                isOpen={updateInvoiceModalOpen}
                orderInvoiceInfo={invoiceInfo}
                handleSubmit={handleUpdateInvoiceModalSubmit}
                onClose={closeUpdateInvoiceModal}
                orderChallanInfo={challanInfo}
              />
            </Box>
            <Box>
              <Tooltip label="Add Order Remarks">
                <IconButton
                  icon={<RiMenuAddFill />}
                  backgroundColor={"#BBE6E3"}
                  color={"black"}
                  aria-label={""}
                  onClick={openOrdersRemarksModel}
                  isDisabled={isCustomerOrderFreezed}
                />
              </Tooltip>
              <TextInputModal
                modalHeader="Order Remarks"
                inputPlaceHolder="Add Order Remarks"
                isOpen={orderRemarksModelOpen}
                onSave={saveOrderRemarks}
                onClose={closeOrdersRemarksModel}
              />
            </Box>
          </HStack>
          <Box display={"flex"} style={{ marginLeft: "auto" }} paddingTop={5}>
            <GaraazGeneralPopover
              ArekeysVisible={false}
              CopierKeys={["orderNo"]}
              title="Parent Return Order Number"
              value={[{ orderNo: parentOrder?.orderNo }]}
              placement={"top-start"}
            >
              <PopoverTrigger>
                <Text
                  fontWeight={"bold"}
                  color={"#1A202C"}
                  cursor={"pointer"}
                  display={"flex"}
                  mr={5}
                >
                  P-RETURN
                  <BiChevronDown
                    style={{ display: "inline", fontSize: "1.5em" }}
                  />
                </Text>
              </PopoverTrigger>
            </GaraazGeneralPopover>

            <GaraazGeneralPopover
              ArekeysVisible={true}
              CopierKeys={["Supplier Code"]}
              title="Associations"
              value={customerSupplierAssociations}
              placement={"top-start"}
            >
              <PopoverTrigger>
                <Text
                  fontWeight={"bold"}
                  color={"#1A202C"}
                  cursor={"pointer"}
                  display={"flex"}
                >
                  ASSOCIATIONS
                  <BiChevronDown
                    style={{ display: "inline", fontSize: "1.5em" }}
                  />
                </Text>
              </PopoverTrigger>
            </GaraazGeneralPopover>
            {(invoiceInfo?.invoiceNo ||
              supplierInvoiceInfo.some((item: any) => item?.invoiceNo)) && (
              <InvoicePopver
                copierKeys={["invoiceNo", "challanNo"]}
                Invoices={[invoiceInfo, ...supplierInvoiceInfo]}
                Challans={[challanInfo, ...supplierChallanInfo]}
              >
                <InvoicePopoverTrigger
                  invoiceStatus={invoiceInfo.invoiceStatus}
                ></InvoicePopoverTrigger>
              </InvoicePopver>
            )}
            <Box ml={5}>
              <Text fontWeight={"bold"} color={"#1A202C"}>
                HANDLER
              </Text>
              <Text
                color="teal.600"
                textTransform={"uppercase"}
                fontWeight={"semibold"}
                fontSize={"x-small"}
              >
                {`${orderHandlerName}`}
              </Text>
            </Box>
          </Box>
        </>
      )}
    </HStack>
  );
};

export default GaraazOrderComponentFooterReturn;
