import { PopoverTrigger, Text, Box, Badge } from "@chakra-ui/react";
import React from "react";
import { BiChevronDown } from "react-icons/bi";

type InvoicePopoverTriggerProps = {
  invoiceStatus: string;
};

const InvoicePopoverTrigger: React.FC<InvoicePopoverTriggerProps> = ({
  invoiceStatus,
}) => {
  return (
    <PopoverTrigger>
      <Box
        display={"flex"}
        flexDirection={"column"}
        alignItems={"flex-start"}
        ml={5}
      >
        <Text
          fontWeight={"bold"}
          color={"#1A202C"}
          cursor={"pointer"}
          display={"flex"}
        >
          INVOICE
          <BiChevronDown style={{ display: "inline", fontSize: "1.5em" }} />
        </Text>
        {invoiceStatus === "PAID" && (
          <Badge color={"green"} fontSize={"xx-small"}>
            {invoiceStatus}
          </Badge>
        )}
      </Box>
    </PopoverTrigger>
  );
};

export default InvoicePopoverTrigger;
